var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-cal", {
        staticStyle: { height: "600px" },
        attrs: {
          xsmall: "",
          "time-from": 1 * 60,
          "time-to": 24 * 60,
          "time-cell-height": 40,
          "default-view": "month",
          "active-view": "month",
          "events-on-month-view": "full",
          events: _vm.events,
          "selected-date": _vm.selectedDate,
          "time-step": 20,
          "on-event-click": _vm.onEventClick,
          "disable-views": ["years", "year", "day"],
        },
      }),
      _c(
        "a-modal",
        {
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("\n        Close\n      ")]
              ),
            ],
            1
          ),
          _c("h2", [_vm._v(_vm._s(_vm.selectedEvent.title))]),
          _c("a-divider"),
          _c("strong", [
            _vm._v(_vm._s(_vm.nFormat(_vm.selectedEvent.startDate))),
          ]),
          _c("strong", [_vm._v("Event details:")]),
          _c("ul", [
            _c("li", [
              _vm._v("Location: " + _vm._s(_vm.selectedEvent.location)),
            ]),
            _c("li", [
              _vm._v("Event starts at: " + _vm._s(_vm.selectedEvent.startTime)),
            ]),
            _c("li", [
              _vm._v("Event ends at: " + _vm._s(_vm.selectedEvent.endTime)),
            ]),
            _c("li", [_vm._v("Cost: €" + _vm._s(_vm.selectedEvent.cost))]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }