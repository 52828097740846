var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-cal", {
        staticClass: "lg-calendar",
        staticStyle: { height: "600px" },
        attrs: {
          xsmall: "",
          "time-from": 1 * 60,
          "time-to": 24 * 60,
          "min-date": _vm.minDate,
          "time-cell-height": 40,
          "default-view": "month",
          "active-view": "month",
          "events-on-month-view": "full",
          "disable-views": ["years", "year", "day"],
          events: _vm.events,
          "time-step": 20,
          "today-button": "",
          "on-event-click": _vm.onEventClick,
        },
        on: {
          "cell-click": _vm.onEventClick2,
          "view-change": function ($event) {
            return _vm.logEvents($event)
          },
        },
      }),
      _c("vue-cal", {
        staticClass: "sm-calendar",
        attrs: {
          xsmall: "",
          "time-from": 1 * 60,
          "time-to": 24 * 60,
          time: false,
          "click-to-navigate": "",
          "default-view": "month",
          "disable-views": ["years", "year", "week"],
          events: _vm.events,
          "on-event-click": _vm.onEventClick,
        },
        on: {
          "view-change": function ($event) {
            return _vm.logEvents($event)
          },
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.selectedEvent.title },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c("span", [
            _c("strong", [
              _vm._v(_vm._s(_vm.nFormat(_vm.selectedEvent.startDate))),
            ]),
            _c("strong", [_vm._v(" Event details:")]),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v("Location: " + _vm._s(_vm.selectedEvent.location)),
            ]),
            _c("li", [
              _vm._v("Event starts at: " + _vm._s(_vm.selectedEvent.startTime)),
            ]),
            _c("li", [
              _vm._v("Event ends at: " + _vm._s(_vm.selectedEvent.endTime)),
            ]),
            _c("li", [_vm._v("Cost: €" + _vm._s(_vm.selectedEvent.cost))]),
          ]),
          _c("div", [
            _c("h4", { staticClass: "joined-member-heading" }, [
              _c("strong", [_vm._v("Joined Members")]),
            ]),
            _c(
              "ul",
              { staticClass: "joined-members" },
              _vm._l(_vm.selectedEvent.members, function (member) {
                return _c(
                  "li",
                  {
                    key: member.id,
                    staticStyle: {
                      color: "blue",
                      cursor: "pointer",
                      "text-decoration": "line",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$router.push("/club/member/" + member.id)
                          },
                        },
                      },
                      [_c("h5", [_vm._v(_vm._s(member.preferred_name))])]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$router.push(
                                  "/teams/" +
                                    _vm.selectedEvent.team_id +
                                    "/session/" +
                                    _vm.selectedEvent.id
                                )
                              },
                            },
                          },
                          [_vm._v("\n              manage\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("\n        Close\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "Select Team" },
          model: {
            value: _vm.showDialog2,
            callback: function ($$v) {
              _vm.showDialog2 = $$v
            },
            expression: "showDialog2",
          },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog2 = false
                    },
                  },
                },
                [_vm._v("\n        Close\n      ")]
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [_c("a-col", { attrs: { span: 24 } }, [_c("club-teams-table")], 1)],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }